import React from 'react';
import dynamic from "next/dynamic";
import Header from "../common/Header";
import category_links from '../../data/category_links'
import getLayoutProps from '../../utils/layout';
import { useRouter } from "next/router";
import { CtaQuery } from '../home/CtaQuery';
import category_questions from '../../data/category_questions';
import { useState } from "react";
import ChatComponent from '../common/Footer/ChatComponent';
import { questionPopUp } from '../../tracking/segment/homePage';
import { getPageURL } from '../../utils/tracking/index';
import { getParamsObjectFromString } from '../../utils';
import { getPineLabsCookie, setPineLabsCookie } from '../../api/cookies';
import { setPineLabsCampaign } from '../../redux/actions/genericActions';
import store from '../../redux'
import { blockSalesTouchPoint } from '../../utils/myaccount';

const LazyLoader = dynamic(()=> import("@/hoc/LazyLoader"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const Footer = dynamic(()=> import("../common/Footer"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const UIModal = dynamic(()=> import("./../common/ui-components/surface/UIModal"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const Layout = (props: any) => {
  const isLayout = props.pageName ? true : false;
  const layoutProps = getLayoutProps(props.pageName, props.layoutConfig)
  const [questions, setQuestions] = useState([])
  const [isQuerymodal, toggleQueryModal] = useState(false)
  const [ind, changeInd] = useState(undefined)
  const [disabled, changeDisabled] = useState(true)
  const [appRender, setAppRender] = useState(true)
  const [pineLabs, setPineLabs] = useState(false)
  const closeModal = () => {
    changeInd(undefined)
    changeDisabled(true)
    toggleQueryModal(false)
    router.push('/')
    return false
  }
  const router = useRouter()
  let pineLabsCampaign = store.getState().genericReducer.pineLabsCampaign
  React.useEffect(() => {
    if(getParamsObjectFromString(window.location.href)['urlParams']['app_render'] === "true"){
      document.body.classList.add('app_render');
      setAppRender(true);
    } else{
      setAppRender(false);
    }
  }, [])

  React.useEffect(()=>{
    store.dispatch(setPineLabsCampaign(getPineLabsCookie()))
    if(pineLabsCampaign != "completed" && (getParamsObjectFromString(window.location.href)['urlParams']['osg_campaign'] === 'pinelabs' || pineLabsCampaign == "initialized" || pineLabsCampaign == "in progress")){
      setPineLabs(true);
      if(!pineLabsCampaign) setPineLabsCookie('initialized');
    }
    else if(pineLabsCampaign == "completed")setPineLabs(false)
  }, [pineLabs,pineLabsCampaign])

  React.useEffect(()=>{
    if(router.query?.question_popup){
      const category:any = router.query?.question_popup;
      if(category_questions[category]){
        if(category_questions[category].page_count==1 || category_questions[category].questions.length == 1){
          let url = category_questions[category]['questions'][0].url
          router.push(`/${url}?nav=search`)
          toggleQueryModal(false)
          changeDisabled(true)
          return
        }else{
          questionPopUp({...getPageURL(router),'category':category})
          setQuestions(category_questions[category]['questions'])
          toggleQueryModal(true)
          changeDisabled(false)
        }
      }else{
        router.push('/')
      }
    }

    return ()=>{
      toggleQueryModal(false)
    }
  },[router.query?.question_popup])

  return (
    <>
      {!appRender && isLayout  && <Header pineLabs={pineLabs} category_links={category_links} {...layoutProps['headerProps']} router={router}/> }
      {props.children}
      {
        isLayout && !layoutProps['noFooter'] && !appRender &&
        <LazyLoader allDevice><Footer pineLabs={pineLabs} blockSalesTouchPoint={blockSalesTouchPoint(router)} {...layoutProps['footerProps']} /></LazyLoader>
      }
      {!pineLabs && !appRender && layoutProps['showChatIcon'] && <ChatComponent ScrollTillElementID={layoutProps['ScrollTillElementID']}/> }
      {isQuerymodal && <UIModal isOpen={isQuerymodal} onOverlayClick={closeModal} onClick={closeModal} larger={true}>
        <CtaQuery disabled={disabled} changeDisabled={changeDisabled} questions={questions} router={router} ind={ind} changeInd={changeInd} toggleQueryModal={toggleQueryModal}/>
      </UIModal>}
    </>
  );
};

export default Layout;
